import { setId } from '@/scripts/helper';

const getStyle = () => {
  return {
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    font_color: {
      rgb: [],
      cmyk: []
    }
  };
};

export const getDefaultImg = () => {
  return {
    node_id: setId(),
    node_type: 'image',
    content: {
      url: '',
      alias_url: ''
    },
    margin: {
      before: '0mm'
    },
    img_size: {
      width: '0mm',
      height: '0mm'
    }
  };
};

export const getDefaultPagenum = () => {
  return {
    node_id: setId(),
    node_type: 'page_number',
    content: {
      text: '@PN',
      url: '',
      alias_url: '',
      offset: ''
    },
    position: {
      x: '0mm',
      y: '0mm'
    },
    style: getStyle(),
    img_size: {
      width: '',
      height: ''
    }
  };
};

export const defaultFormat = function (cfg) {
  if (!cfg) return [];
  const { components: [{ page_cfg: { page_size, page_margin } }] } = cfg;

  return [
    {
      name: '空白（订口图）',
      type: 'image',
      img: require('./img/空白（订口图）.jpg'),
      leftData: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.height,
              height: page_margin.left,
              style: getStyle(),
            },
          }
        ]
      }
    },
    {
      name: '空白（切口图）',
      type: 'image',
      img: require('./img/空白（切口图）.jpg'),
      rightData: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.height,
              height: page_margin.right
            },
          }
        ]
      }
    },
    {
      name: '空白（订切口图）',
      type: 'image',
      img: require('./img/空白（订切口图）.jpg'),
      leftData: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.height,
              height: page_margin.left
            },
          },
        ]
      },
      rightData: {
        relative_margin: '0mm',
        offset: '0mm',
        margin_before: '0mm',
        data: [
          {
            node_id: setId(),
            node_type: 'image',
            content: {
              url: ''
            },
            margin: {
              before: '0mm'
            },
            img_size: {
              width: page_size.height,
              height: page_margin.right
            },
          },
        ]
      }
    }
  ];
};
