<template>
  <el-card class="book-header">
    <div class="header">
      <input ref="fileLeft" type="file" class="file-input" :accept="leftActImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="fileChange($event, leftImgComponents[0], 'left')">
      <input ref="fileRight" type="file" class="file-input" :accept="rightActImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="fileChange($event, rightImgComponents[0], 'right')">
      <input ref="pageNumFile" type="file" class="file-input" :accept="actPageNumImg === 0 ? `image/jpeg, image/png` : `image/tiff, application/postscript`" :value="fileVal" @change="pageNumFileChange($event, pagenumComponents[0])">

      <!-- 左右边栏标签：全部页面、page -->
      <!-- 左（奇数）页边栏、右（偶数）页边栏标签：odd、even -->

      <tabs v-model="tabIndex" :tabs="tabs" class="inline-tab" />

      <div class="check-box">
        <el-checkbox-group :value="checkList" @input="changeCheckList">
          <el-checkbox label="边栏分左右页显示"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="check-box">
        <el-checkbox-group :value="enabledFbd" @input="changeEnabledFbd">
          <el-checkbox label="是否使用方正代码进行配置"></el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="body">
      <div v-if="!enabledFbd" class="preview" ref="preview">
        <div class="btns">
          <el-button icon="el-icon-refresh-right" class="style-tool-button--text" @click="computePreview"></el-button>
          <el-button icon="el-icon-minus" @click="transformScale -= 0.3" class="style-tool-button--text"></el-button>
          <el-button icon="el-icon-plus" @click="transformScale += 0.3" class="style-tool-button--text"></el-button>
        </div>
        <div class="page" :style="pageStyle" ref="content">
          <div class="margin-top" :style="{ height: marginStyle.top }"></div>
          <div class="margin-center">
            <div class="margin-left" :style="{ width: marginStyle.left }">
              <div class="watermark" :style="sidebarStyle.left"
                v-if="leftImgComponents.length && leftImgComponents[0].content.url"
                @dblclick="uploadLeftImage">
                <img :src="leftImgComponents[0].content.url + '?x-oss-process=image/format,jpg'" alt="" style="width: 100%;height: 100%" />
              </div>
              <div v-else-if="leftImgComponents.length" class="empty" @dblclick="uploadLeftImage">
                <span>
                  双击此处添加图片
                </span>
              </div>
            </div>
            <div class="page-core">
              <div class="margin-page-num" v-if="pagenumComponents.length" :style="pagenumStyle">
                <img :src="pagenumComponents[0].content.url" alt="" v-if="pagenumComponents[0].content.url" :style="{width: pagenumComponents[0].img_size.width, height: pagenumComponents[0].img_size.height}">
                <div v-if="pagenumComponents[0].content.url" :style="{ height: lineSpacing }"></div>
                <div :style="pageNumOffset">{{pagenumComponents[0].content.text.replace('@PN', '1')}}</div>
              </div>
            </div>
            <div class="margin-right" :style="{ width: marginStyle.right }">
              <div class="watermark" :style="sidebarStyle.right"
                v-if="rightImgComponents.length && rightImgComponents[0].content.url"
                @dblclick="uploadRightImage">
                <img :src="rightImgComponents[0].content.url" alt="" style="width: 100%;height: 100%" />
              </div>
              <div v-else-if="rightImgComponents.length" class="empty" @dblclick="uploadRightImage">
                <span>
                  双击此处添加图片
                </span>
              </div>
            </div>
          </div>
          <div class="margin-bottom" :style="{ height: marginStyle.bottom }">
          </div>
        </div>
      </div>
      <div v-if="!enabledFbd" class="operate">
        <div class="style-lib" :class="{ closed }">
          <div class="toggle" @click="closed=!closed">页脚版式 <i class="el-icon-caret-top"></i></div>
          <div class="condition-box">
            <div class="left">
            </div>
            <div class="right">
              <i class="el-icon-search"></i>
              <input type="text" placeholder="输入关键字" v-model="condition.search">
            </div>
          </div>
          <el-tabs class="style-lib-tabs" v-model="condition.formatModel">
            <el-tab-pane v-for="(item, index) in formatList" :key="item.label + index" :name="item.value">
              <span slot="label" class="tab-item">
                <img :src="item.img" alt="" class="tab-item-img">
                <span class="tab-item-text">{{item.label}}</span>
              </span>
              <div class="style-box">
                <div class="item" v-for="(child, i) in formatDataList" :key="i + child.data.name" :class="{'act-item': actFormat.data.name===child.data.name}">
                  <i class="el-icon-error del" @click.stop="delComponent(child)"></i>
                  <el-image :src="child.data.img" alt="" class="img" fit="cover" />
                  <div class="text-box">
                    <i class="el-icon-success" v-if="actFormat.data.name===child.data.name"></i>
                    <span>{{child.data.name}}</span>
                  </div>
                  <div class="button-box">
                    <el-button size="mini" class="button" @click="selectFormat(child)">套用</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="config">
          <el-timeline>
            <el-timeline-item hide-timestamp :icon="actDot.includes('0') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('0') ? 'primary' : 'info'" size="large">
              <span class="timeline-title" @click="handleDot('0')">整体效果</span>
              <el-button class="style-tool-button--text m-l-20" @click.stop="setComponent" icon="el-icon-plus">生成版式</el-button>
              <el-row v-if="actDot.includes('0')" class="config-item">
                <el-form :inline="true" size="mini" label-width="120px">
                  <el-form-item label="左边栏距版心距离：">
                    <unit-input v-model="component.left.relative_margin"  unit="mm"/>
                    <span>&nbsp;mm</span>
                  </el-form-item>
                  <br>
                  <el-form-item label="右边栏距版心距离：">
                    <unit-input v-model="component.right.relative_margin"  unit="mm"/>
                    <span>&nbsp;mm</span>
                  </el-form-item>
                </el-form>
              </el-row>
            </el-timeline-item>
            <el-timeline-item hide-timestamp :icon="actDot.includes('1') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('1') ? 'primary' : 'info'" size="large">
              <span class="timeline-title" @click="handleDot('1')">左边栏配图</span>
              <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addImg('left', '1')">新增</el-button>
              <el-row v-if="actDot.includes('1')" class="config-item">
                <div class="img-item" v-for="(item, index) in leftImgComponents" :key="item.node_id">
                  <div class="header">
                    <div>左边栏配图{{String.fromCharCode(index + 9312)}}</div>
                    <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
                  </div>
                  <div class="img-body">
                    <el-form :inline="true" size="mini" label-position="right" label-width="80px">
                      <el-form-item label="配图上传：">
                          <el-row class="m-b-10">
                            <span style="display: inline-block; width: 60px">配图
                              <el-tooltip class="item" effect="light" content="提示：配图不支持tif, eps格式，需要该格式的图片可以上传到备用图" placement="top" :open-delay="300">
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </span>
                            <el-badge :is-dot="!!item.content.url">
                              <el-button class="style-tool-button" @click="uploadLeftImage">上传</el-button>
                            </el-badge>
                          </el-row>
                          <el-row>
                            <span style="display: inline-block; width: 60px">备用图 </span>
                            <el-badge :is-dot="!!item.content.alias_url">
                              <el-button class="style-tool-button" @click="uploadLeftAliasImage">上传</el-button>
                            </el-badge>
                          </el-row>
                      </el-form-item>
                      <el-form-item label="图片坐标：">
                        <span class="label">Y </span>
                        <unit-input v-model="item.margin.before" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                      </el-form-item>
                      <el-form-item label="图片尺寸：">
                        <el-row class="connection-box">
                          <div class="connection-style">
                            <svg-icon name="chain" class="icon-connection"></svg-icon>
                          </div>
                          <div class="input-box">
                            <span class="label m-b-10">高 </span>
                            <unit-input v-model="item.img_size.height" unit="mm" step="1" min="0" @input="handleHeight($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                          <div class="input-box">
                            <span class="label">宽 </span>
                            <unit-input v-model="item.img_size.width" unit="mm" step="1" min="0" @input="handleWidth($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                        </el-row>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-row>
            </el-timeline-item>
            <el-timeline-item hide-timestamp :icon="actDot.includes('2') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('2') ? 'primary' : 'info'" size="large">
              <span class="timeline-title" @click="handleDot('2')">右边栏配图</span>
              <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addImg('right', '2')">新增</el-button>
              <el-row v-if="actDot.includes('2')" class="config-item">
                <div class="img-item" v-for="(item, index) in rightImgComponents" :key="item.node_id">
                  <div class="header">
                    <div>右边栏配图{{String.fromCharCode(index + 9312)}}</div>
                    <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
                  </div>
                  <div class="img-body">
                    <el-form :inline="true" size="mini" label-position="right" label-width="80px">
                      <el-form-item label="配图上传：">
                        <el-row class="m-b-10">
                          <span style="display: inline-block; width: 60px">配图
                            <el-tooltip class="item" effect="light" content="提示：如果配图不能显示在页面中，请上传备用图用作预览" placement="top" :open-delay="300">
                              <i class="el-icon-info"></i>
                            </el-tooltip>
                          </span>
                          <el-badge :is-dot="!!item.content.url">
                            <el-button class="style-tool-button" @click="uploadRightImage">上传</el-button>
                          </el-badge>
                        </el-row>
                        <el-row>
                          <span style="display: inline-block; width: 60px">备用图 </span>
                          <el-badge :is-dot="!!item.content.alias_url">
                            <el-button class="style-tool-button" @click="uploadRightAliasImage">上传</el-button>
                          </el-badge>
                        </el-row>
                      </el-form-item>
                      <el-form-item label="图片坐标：">
                        <span class="label">Y </span>
                        <unit-input v-model="item.margin.before" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                      </el-form-item>
                      <el-form-item label="图片尺寸：">
                        <el-row class="connection-box">
                          <div class="connection-style">
                            <svg-icon name="chain" class="icon-connection"></svg-icon>
                          </div>
                          <div class="input-box">
                            <span class="label m-b-10">高 </span>
                            <unit-input v-model="item.img_size.height" unit="mm" step="1" min="0" @input="handleHeight($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                          <div class="input-box">
                            <span class="label">宽 </span>
                            <unit-input v-model="item.img_size.width" unit="mm" step="1" min="0" @input="handleWidth($event, item)" />
                            <span class="unit"> mm</span>
                          </div>
                        </el-row>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-row>
            </el-timeline-item>
            <el-timeline-item hide-timestamp :icon="actDot.includes('3') ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" :type="actDot.includes('3') ? 'primary' : 'info'" size="large">
              <span class="timeline-title" @click="handleDot('3')">边栏页码</span>
              <el-button class="style-tool-button--text m-l-20" icon="el-icon-plus" @click="addPagenum">新增</el-button>
              <el-row v-if="actDot.includes('3')" class="config-item">
                <div class="pagenum-item" v-for="(item, index) in pagenumComponents" :key="item.node_id">
                  <div class="header">
                    <div>边栏页码{{String.fromCharCode(index + 9312)}}</div>
                    <el-button class="style-tool-button--text" icon="el-icon-delete" @click="delItem(item)">删除</el-button>
                  </div>
                  <div class="pagenum-body">
                    <el-form :inline="true" size="mini" label-position="right" label-width="80px">
                      <el-form-item label="页码样式：">
                        <el-select :value="checkPagenum(item.content.text)" @input="changePagenum($event, item.content)" class="style-tool-select" :popper-append-to-body="false">
                          <el-option label="1, 2, 3..." value="@PN" />
                          <el-option label="-1-, -2-, -3-..." value="-@PN-" />
                          <el-option label="·1·, ·2·, ·3·... " value="·@PN·" />
                          <el-option label="自定义" value="自定义" />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="页码效果：">
                        <el-input v-model="item.content.text" class="style-tool-input" style="width: 80px;" placeholder="-@PN-" />
                      </el-form-item>
                      <br>
                      <el-form-item label="页码字体：">
                        <el-select v-model="item.style.font_family_num" :placeholder="'白正（默认）'" :popper-append-to-body="false" clearable class="style-tool-select" filterable>
                          <el-option
                            v-for="(value, key) in fontFamilyEn"
                            :key="value"
                            :label="key"
                            :value="value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="字号：">
                        <el-select v-model="item.style.font_size" placeholder="10.5" :popper-append-to-body="false" clearable class="style-tool-select">
                          <el-option v-for="item in fontSize" :key="item.key" :label="item.key" :value="item.value" />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="页码颜色：">
                        <div class="font-style">
                          <div class="font-style-item">
                            <el-popover
                              placement="bottom"
                              width="220"
                              popper-class="style-tool-color-popover"
                              trigger="click">
                              <div class="style-tool-cmyk-box">
                                <span class="label">CMYK: </span>
                                <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[0]" @input="inputCmyk($event, 0, item)" placeholder="C"></el-input></span>
                                <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[1]" @input="inputCmyk($event, 1, item)" placeholder="M"></el-input></span>
                                <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[2]" @input="inputCmyk($event, 2, item)" placeholder="Y"></el-input></span>
                                <span v-if="item.style.font_color.cmyk"><el-input :value="item.style.font_color.cmyk[3]" @input="inputCmyk($event, 3, item)" placeholder="K"></el-input></span>
                                <el-color-picker :value="getColor(item.style.font_color.rgb)" size="mini" color-format="rgb"  @active-change="transformColor($event, item)" ref="picker" />
                              </div>
                              <div class="font-style-item-shadow" slot="reference">
                                <div class="font-style-item-shadow-font">A</div>
                                <div class="font-style-item-shadow-color" :style="{ 'background-color': getColor(item.style.font_color.rgb) }" />
                              </div>
                            </el-popover>
                          </div>
                        </div>
                      </el-form-item>
                      <br>
                      <el-form-item label="页码坐标：">
                        <span class="label">X </span>
                        <unit-input v-model="item.position.x" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                        <span style="padding-left: 40px;"></span>
                        <span class="label">Y </span>
                        <unit-input v-model="item.position.y" unit="mm" step="1" />
                        <span class="unit"> mm</span>
                      </el-form-item>
                      <br>
                      <el-form-item>
                        <span style="display: inline-block; width: 60px" slot="label">配图
                            <el-tooltip class="item" effect="light" content="提示：配图不支持tif, eps格式，需要该格式的图片可以上传到备用图" placement="top" :open-delay="300">
                              <i class="el-icon-info"></i>
                            </el-tooltip>
                            ：
                          </span>
                          <el-badge :is-dot="!!item.content.url" style="display: inline">
                            <el-button class="style-tool-button" style="padding: 0;" @click="uploadPageNumImage">上传</el-button>
                          </el-badge>
                          <el-button class="style-tool-button m-l-5" style="padding: 0; vertical-align: middle; background: #ccc;" @click="delPageNumImg(item)">删除</el-button>
                        </el-form-item>
                        <template v-if="item.content.url">
                          <el-form-item>
                            <span style="display: inline-block; width: 60px" slot="label">备用图：
                              </span>
                              <el-badge :is-dot="!!item.content.alias_url" style="display: inline">
                                <el-button class="style-tool-button" style="padding: 0;" @click="uploadPageNumAliasImage">上传</el-button>
                              </el-badge>
                              <el-button class="style-tool-button m-l-5" style="padding: 0; vertical-align: middle; background: #ccc;" @click="item.content.alias_url = ''">删除</el-button>
                          </el-form-item>
                          <el-form-item label="图文偏移：">
                            <unit-input v-model="item.content.offset" step="1" unit="mm"/>
                            <span class="unit"> mm</span>
                          </el-form-item>
                          <el-form-item label="图片尺寸：">
                          <el-row class="connection-box">
                            <div class="connection-style">
                              <svg-icon name="chain" class="icon-connection"></svg-icon>
                            </div>
                            <div class="input-box">
                              <span class="label m-b-10">高 </span>
                              <unit-input v-model="item.img_size.height" step="1" min="0" unit="mm" @input="handleHeight($event, item)" />
                              <span class="unit"> mm</span>
                            </div>
                            <div class="input-box">
                              <span class="label">宽 </span>
                              <unit-input v-model="item.img_size.width" step="1" min="0" unit="mm" @input="handleWidth($event, item)" />
                              <span class="unit"> mm</span>
                            </div>
                          </el-row>
                        </el-form-item>
                      </template>
                    </el-form>
                  </div>
                </div>
              </el-row>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div v-if="enabledFbd && component.left.fbd" class="book-sidebar-fbd-code" style="width: 100%">
        <span style="display: block;font-size: 14px;line-height: 50px;">左边栏三方代码：</span>
        <FbdEditor
          v-model="component.left.fbd.code_template"
          :options="{ height: 150 }"
        />
        <span style="display: block;font-size: 14px;line-height: 50px;">右边栏三方代码：</span>
        <FbdEditor
          v-model="component.right.fbd.code_template"
          :options="{ height: 150 }"
        />
      </div>
    </div>
  </el-card>
</template>

<script>
import UnitInput from '../components/UnitInput';
import { getDPI } from '@/scripts/helper';
import { defaultFormat, getDefaultImg, getDefaultPagenum } from './assets/format.js';
import { fontSize, fontFamilyCn, fontFamilyEn, replaceFontSizeMap, webFontFamily } from '@/assets/styleTool/map';
import _ from 'lodash';
import ScrollBooster from 'scrollbooster';
import { catalog } from '../assets/componentCatalog.js';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    UnitInput,
    FbdEditor,
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  created () {
    this.init();
  },
  mounted () {
    const viewport = this.$refs.preview;
    const content = this.$refs.content;
    if (!viewport || !content) {
      return;
    }
    this.sb = new ScrollBooster({
      viewport,
      content,
      emulateScroll: true,
      onUpdate: ({ position }) => {
        content.style.transform = `translate(
          ${-position.x}px,
          ${-position.y}px
        )`;
      }
    });
    this.computePreview();
    window.addEventListener('resize', this.computePreview);
  },
  destroyed () {
    window.removeEventListener('resize', this.computePreview);
  },
  data () {
    return {
      sb: null,
      dpi: getDPI(),
      fileVal: '',
      files: null,
      fontSize,
      formatData: [],
      rightActImg: 0,
      leftActImg: 0,
      actPageNumImg: 0,
      data: null,
      checkList: [], // 左右边栏的模式
      enabledFbd: false, // 是否使用方正代码进行配置
      actDot: ['0'],
      defaultFormat: defaultFormat(this.styleData),
      tabIndex: 0,
      closed: false,
      actFormat: {
        data: {}
      },
      condition: {
        formatModel: '',
        search: ''
      },
      margin_type: ['left', 'right'],
      transformScale: 1,
    };
  },
  watch: {
    transformScale () {
      this.$nextTick(function () {
        this.sb && this.sb.updateMetrics();
      });
    }
  },
  computed: {
    lineSpacing () {
      let { styleData: { components: [{ default_style: { font_size, line_spacing } }] } } = this;
      font_size = replaceFontSizeMap[font_size] || font_size;
      line_spacing = typeof line_spacing === 'number' ? line_spacing * parseFloat(font_size) + 'pt' : line_spacing;
      return line_spacing;
    },
    fontFamilyCn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'cn').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyCn, ...temp };
    },
    fontFamilyEn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'en').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyEn, ...temp };
    },
    pagenumStyle () {
      const { pagenumComponents: [pagenum], styleData: { components: [{ default_style: { font_size, font_family_cn, font_color: { rgb } } }] } } = this;
      if (!pagenum) return;
      const { position: { x, y }, style: { font_size: itemFontSize, font_family_cn: itemFontFamilyCn, font_color: { rgb: itemRgb } } } = pagenum;
      const calcFontSize = replaceFontSizeMap[itemFontSize] || itemFontSize || replaceFontSizeMap[font_size] || font_size;
      return {
        fontSize: calcFontSize,
        lineHeight: calcFontSize,
        fontFamily: webFontFamily[itemFontFamilyCn] || webFontFamily[font_family_cn],
        color: `rgb(${itemRgb?.join(',') || rgb?.join(',')})`,
        left: parseFloat(x) * this.transformScale + 'px',
        top: parseFloat(y) * this.transformScale + 'px',
        textAlign: 'center',
      };
    },
    pageNumOffset () {
      const { pagenumComponents: [pagenum] } = this;
      if (!pagenum) return;
      const { content: { offset } } = pagenum;
      return {
        marginTop: `${-parseFloat(offset) || 0}mm`
      };
    },
    sidebarStyle () {
      const [leftImg] = this.leftImgComponents;
      const [rightImg] = this.rightImgComponents;
      const { left: { relative_margin: leftMargin }, right: { relative_margin: rightMargin } } = this.component;
      let left = {};
      let right = {};
      if (leftImg) {
        const { img_size: { width, height }, margin: { before } } = leftImg;

        left = {
          width: parseFloat(width) * this.transformScale + 'px',
          height: parseFloat(height) * this.transformScale + 'px',
          right: parseFloat(leftMargin) * this.transformScale + 'px',
          top: parseFloat(before) * this.transformScale + 'px',
          position: 'absolute',
        };
      }
      if (rightImg) {
        const { img_size: { width, height }, margin: { before } } = rightImg;
        right = {
          width: parseFloat(width) * this.transformScale + 'px',
          height: parseFloat(height) * this.transformScale + 'px',
          left: parseFloat(rightMargin) * this.transformScale + 'px',
          top: parseFloat(before) * this.transformScale + 'px',
          position: 'absolute',
        };
      }
      return {
        left,
        right
      };
    },
    marginStyle () {
      if (!this.transformScale) return {};
      const { page_margin: { top, right, bottom, left } } = this.styleData.components[0].page_cfg;
      return {
        top: parseFloat(top) * this.transformScale + 'px',
        right: parseFloat(right) * this.transformScale + 'px',
        bottom: parseFloat(bottom) * this.transformScale + 'px',
        left: parseFloat(left) * this.transformScale + 'px'
      };
    },
    pageStyle () {
      let { components: [{ page_cfg: { page_size: { width, height } } }] } = this.styleData;
      width = parseFloat(width);
      height = parseFloat(height);
      return {
        width: width * this.transformScale + 'px',
        height: height * this.transformScale + 'px'
      };
    },
    leftImgComponents () {
      const { left: { data } } = this.component;
      return data.filter(item => item.node_type === 'image');
    },
    rightImgComponents () {
      const { right: { data } } = this.component;
      return data.filter(item => item.node_type === 'image');
    },
    pagenumComponents () {
      const { left: { data } } = this.component;
      return data.filter(item => item.node_type === 'page_number');
    },
    formatList () {
      const formatList = catalog.module.sidebar.model;
      if (!formatList) return [];
      return formatList;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    config () {
      return this.component;
    },
    component () {
      const { data: { margin_components }, margin_type } = this;
      const { page_selector } = this.tabs[this.tabIndex];
      const left = margin_components.find(item =>
        item.margin_type === margin_type[0] && item.page_selector === page_selector);
      const right = margin_components.find(item =>
        item.margin_type === margin_type[1] && item.page_selector === page_selector);
      left.fbd = left.fbd || {};
      left.fbd.enable_fbd = left.fbd.enable_fbd || true;
      left.fbd.code_template = left.fbd.code_template || '';
      right.fbd = right.fbd || {};
      right.fbd.enable_fbd = right.fbd.enable_fbd || true;
      right.fbd.code_template = right.fbd.code_template || '';
      return { left, right };
    },
    tabs () {
      const tabs = [];
      const { checkList } = this;
      if (checkList.includes('边栏分左右页显示')) {
        tabs.push({ name: '左页边栏', index: tabs.length, page_selector: 'odd' }, { name: '右页边栏', index: tabs.length + 1, page_selector: 'even' });
      } else {
        tabs.push({ name: '左右边栏', index: tabs.length, page_selector: 'page' });
      }
      return tabs;
    }
  },
  methods: {
    delPageNumImg (item) {
      // console.log(item)
      item.content.url = '';
      item.content.offset = '';
      item.alias_url = '';
      item.img_size = {
        width: '',
        height: ''
      };
    },
    handleWidth (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.height = Math.round((parseFloat(val) / (img.width / this.dpi.y * 25.4) * (img.height / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.width = val;
        };
      }
    },
    handleHeight (val, { img_size, content: { url } }) {
      if (val !== '' && url) {
        const img = new Image();
        img.src = url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          img_size.width = Math.round((parseFloat(val) / (img.height / this.dpi.y * 25.4) * (img.width / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
          img_size.height = val;
        };
      }
    },
    uploadLeftImage () {
      this.leftActImg = 0;
      this.$nextTick(function () {
        this.$refs.fileLeft.click();
      });
    },
    uploadLeftAliasImage () {
      this.leftActImg = 1;
      this.$nextTick(function () {
        this.$refs.fileLeft.click();
      });
    },
    uploadRightImage () {
      this.rightActImg = 0;
      this.$nextTick(function () {
        this.$refs.fileRight.click();
      });
    },
    uploadRightAliasImage () {
      this.rightActImg = 1;
      this.$nextTick(function () {
        this.$refs.fileRight.click();
      });
    },
    uploadPageNumImage () {
      this.actPageNumImg = 0;
      this.$nextTick(function () {
        this.$refs.pageNumFile.click();
      });
    },
    uploadPageNumAliasImage () {
      this.actPageNumImg = 1;
      this.$nextTick(function () {
        this.$refs.pageNumFile.click();
      });
    },
    setComponent () {
      const data = _.cloneDeep(this.config);
      delete data.page_selector;
      delete data.margin_type;
    },
    computePreview () {
      if (!this.$refs.preview) {
        return;
      }
      const { offsetWidth, offsetHeight } = this.$refs.preview;
      let { page_size: { width, height } } = this.styleData.components[0].page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      const scale = width / height;
      if (scale > offsetWidth / offsetHeight) {
        this.transformScale = offsetWidth * 0.9 / width;
      } else {
        this.transformScale = offsetHeight * 0.9 / height;
      }
    },
    setPageNumUrl (url, item) {
      // console.log(item)
      if (this.actPageNumImg === 1) {
        item.content.alias_url = url;
        return;
      }
      const img = new Image();
      img.src = url;
      item.content.url = url;
      img.onload = () => {
        const type = this.actFormat.type && this.actFormat.type.split('_') || [];
        let { components: [{ page_cfg: { page_margin: { top: maxHeight }, page_core_size: { width: maxWidth } } }] } = this.styleData;
        maxHeight = parseFloat(maxHeight);
        maxWidth = parseFloat(maxWidth);
        const width = Math.round((img.width / this.dpi.x * 25.4) * 10) / 10;
        const height = Math.round((img.height / this.dpi.y * 25.4) * 10) / 10;
        const scale = width / height;
        const heightValue = parseFloat(item.img_size.width) / scale;
        if (type.includes('background')) {
          if (heightValue > maxHeight) { // 这时候宽是百分百。高大了，取高为最大值，缩小宽度
            item.img_size.height = maxHeight + 'mm';
            item.img_size.width = maxHeight * scale + 'mm';
          } else {
            item.img_size.height = heightValue + 'mm';
          }
          return;
        } else {
          if (maxWidth / maxHeight > scale) {
            if (height > maxHeight) {
              item.img_size = {
                width: maxHeight * scale + 'mm',
                height: maxHeight + 'mm'
              };
              return;
            }
          } else {
            if (width > maxWidth) {
              item.img_size = {
                width: maxWidth + 'mm',
                height: maxWidth / scale + 'mm'
              };
              return;
            }
          }
        }
        item.img_size = {
          width: width + 'mm',
          height: height + 'mm'
        };
      };
    },
    setUrl (url, item, type) {
      if (type === 'left' && this.leftActImg === 1) {
        item.content.alias_url = url;
        return;
      }
      if (type === 'right' && this.rightActImg === 1) {
        item.content.alias_url = url;
        return;
      }
      const img = new Image();
      img.src = url + '?x-oss-process=image/format,jpg';
      item.content.url = url;
      img.onload = () => {
        const type = this.actFormat.type && this.actFormat.type.split('-') || [];
        let { components: [{ page_cfg: { page_margin: { left: maxWidth }, page_core_size: { height: maxHeight } } }] } = this.styleData;
        maxHeight = parseFloat(maxHeight);
        maxWidth = parseFloat(maxWidth);
        item.natural_size = { width: img.width, height: img.height };
        const width = Math.round((img.width / this.dpi.x * 25.4) * 10) / 10;
        const height = Math.round((img.height / this.dpi.y * 25.4) * 10) / 10;
        const scale = width / height;
        const heightValue = parseFloat(item.img_size.width) / scale;
        if (type.includes('background')) {
          if (heightValue > maxHeight) { // 这时候宽是百分百。高大了，取高为最大值，缩小宽度
            item.img_size.height = maxHeight + 'mm';
            item.img_size.width = maxHeight * scale + 'mm';
          } else {
            item.img_size.height = heightValue + 'mm';
          }
          return;
        } else {
          if (maxWidth / maxHeight > scale) {
            if (height > maxHeight) {
              item.img_size = {
                width: maxHeight * scale + 'mm',
                height: maxHeight + 'mm'
              };
              return;
            }
          } else {
            if (width > maxWidth) {
              item.img_size = {
                width: maxWidth + 'mm',
                height: maxWidth / scale + 'mm'
              };
              return;
            }
          }
        }
        item.img_size = {
          width: width + 'mm',
          height: height + 'mm'
        };
      };
    },
    fileChange (evt, item, type) {
      const file = evt.target.files[0];
      this.files = file;
      this.fileUploadOss(item, type);
    },
    pageNumFileChange (evt, item) {
      const file = evt.target.files[0];
      this.files = file;
      this.pageNumFileUploadOss(item);
    },
    transformColor (val, item) {
      let rgb = val && val.match(/(?<=\()(\d+), *(\d+), *(\d+)(?=\))/)[0] || '';
      if (rgb) {
        rgb = rgb.split(',').map(item => +item);
      } else {
        rgb = [];
      }
      item.style.font_color.rgb = rgb;
      if (rgb.length) {
        item.style.font_color.cmyk = this.rgb2cmyk(rgb);
      } else {
        item.style.font_color.cmyk = [];
      }
    },
    inputCmyk (val, position, item) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { font_color, font_color: { cmyk } } = item.style;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      font_color.rgb = this.cmykToRGB(cmyk);
    },
    rgb2cmyk ([r, g, b]) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));
      c = Math.round((c - k) / (1 - k) * 100);
      m = Math.round((m - k) / (1 - k) * 100);
      y = Math.round((y - k) / (1 - k) * 100);
      k = Math.round(k * 100);
      c = isNaN(c) ? 0 : c;
      m = isNaN(m) ? 0 : m;
      y = isNaN(y) ? 0 : y;
      k = isNaN(k) ? 0 : k;
      return [c, m, y, k];
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    getColor (rgb) {
      if (!rgb || !rgb.length) {
        return 'rgb(0, 0, 0)';
      }
      return `rgb(${rgb.join()})`;
    },
    init () {
      this.data = this.styleData.components[0];
      let { margin_components } = this.data;
      if (!margin_components) margin_components = this.$set(this.data, 'margin_components', []);
      const { margin_type } = this;
      if (!margin_components.length || !margin_type.find(item => margin_components.find(item1 => item1.margin_type === item))) {
        margin_components.push(...this.defaultMarginComponent());
      }
      const marginComponent = margin_components.find(item => item.margin_type === margin_type[0]);
      const { page_selector } = marginComponent;
      if (page_selector !== 'page') {
        this.checkList.push('边栏分左右页显示');
      }
      // 目前仅支持选择全部开启三方代码、或者全部不开启
      this.enabledFbd = margin_components.some(item => item.fbd?.enable_fbd);
    },
    addImg (marginType, dot) {
      const { component: { left: { data: leftData }, right: { data: rightData } }, leftImgComponents, rightImgComponents } = this;
      if (marginType === 'left') {
        if (leftImgComponents.length > 0) {
          this.$message.info('目前暂时只支持一个配图');
          return;
        }
        leftData.push(getDefaultImg());
      } else {
        if (rightImgComponents.length > 0) {
          this.$message.info('目前暂时只支持一个配图');
          return;
        }
        rightData.push(getDefaultImg());
      }
      if (!this.actDot.includes(dot)) this.handleDot(dot);
    },
    addPagenum () {
      const { component: { left: { data } }, pagenumComponents } = this;
      if (pagenumComponents.length > 0) {
        this.$message.info('目前暂时只支持一个页码');
        return;
      }
      data.push(getDefaultPagenum());
      if (!this.actDot.includes('3')) this.handleDot('3');
    },
    delItem ({ node_id }) {
      const { component: { left: { data: leftData }, right: { data: rightData } } } = this;
      const letIndex = leftData.findIndex(item => item.node_id === node_id);
      const rightIndex = rightData.findIndex(item => item.node_id === node_id);
      letIndex >= 0 && leftData.splice(letIndex, 1);
      rightIndex >= 0 && rightData.splice(rightIndex, 1);
    },
    checkPagenum (pagenum) {
      if (pagenum === '@PN' || pagenum === '-@PN-' || pagenum === '·@PN·') return pagenum;
      return '自定义';
    },
    changePagenum (pagenum, content) {
      if (pagenum === '自定义') return;
      content.text = pagenum;
    },
    checkText (text) {
      return /^@CPLV[1-9]$/.test(text) ? 'title' : 'text';
    },
    changeTextType (val, content) {
      if (val === 'text') {
        content.text = '';
      } else {
        content.text = '@CPLV1';
      }
    },
    defaultMarginComponent (page_selector = 'page') {
      return this.margin_type.map(item => {
        return {
          margin_type: item,
          page_selector,
          relative_margin: '0mm',
          margin_before: '0mm',
          offset: '0mm',
          data: [],
          fbd: { enable_fbd: true, code_template: '' },
        };
      });
    },
    handleDot (item) {
      const index = this.actDot.indexOf(item);
      if (index >= 0) {
        this.actDot.splice(index, 1);
      } else {
        this.actDot.push(item);
      }
    },
    selectFormat (item) {
      this.actFormat = item;
      const { left, right } = _.cloneDeep(item.data.data);
      if (left.page_selector) delete left.page_selector;
      if (right.page_selector) delete right.page_selector;
      Object.assign(this.component.left, left);
      Object.assign(this.component.right, right);
    },
    changeCheckList (val) {
      let { margin_components } = this.data;
      margin_components = margin_components.filter(item => !this.margin_type.includes(item.margin_type));
      if (val.includes('边栏分左右页显示')) {
        const pageMargin = this.data.margin_components.filter(item =>
          this.margin_type.includes(item.margin_type) && item.page_selector === 'page');
        if (pageMargin) {
          margin_components.push(
            ..._.cloneDeep(pageMargin).map(item => Object.assign(item, { page_selector: 'odd' })),
            ..._.cloneDeep(pageMargin).map(item => Object.assign(item, { page_selector: 'even' })),
          );
        } else {
          margin_components.push(
            ...this.defaultMarginComponent('odd'),
            ...this.defaultMarginComponent('even'),
          );
        }
      } else {
        margin_components.push(...this.defaultMarginComponent());
      }
      this.data.margin_components = margin_components;
      this.tabIndex = this.tabs[0]?.index;
      this.checkList = val;
    },
    changeEnabledFbd (enabledFbd) {
      this.data.margin_components.forEach(margin_component => {
        margin_component.fbd = margin_component.fbd || {};
        margin_component.fbd.enable_fbd = enabledFbd;
        margin_component.fbd.code_template = margin_component.fbd.code_template || '';
      });
      this.enabledFbd = enabledFbd;
    },
  }
};
</script>

<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;

.book-header {
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    flex: none;
    display: flex;
    align-items: center;
    .inline-tab {
      /deep/ .tabs-item {
        min-width: 100px;
      }
      /deep/ .slide {
        transition: transform .2s;
      }
    }
    .check-box {
      margin-left: 10px;
      /deep/ .el-checkbox {
        margin: 10px;
      }
      /deep/ .el-checkbox__label {
        width: unset;
      }
    }
  }
  .body {
    flex: auto;
    display: flex;
    margin-top: 4px;
    height: calc(100% - 46px);
    .preview {
      flex: none;
      width: 50%;
      background-color: #ccc;
      box-shadow: 0px 0px 4px black inset;
      border: 1px solid #ccc;
      position: relative;
      overflow: hidden;
      .btns {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        padding: 12px;
      }
      .page {
        background-color: #fff;
        border: 1px solid #ccc;
        display: flex;
        position: relative;
        flex-direction: column;
        font-size: 12px;
        color: #888;
        flex: none;
        margin: 0 auto;
        margin-top: 40px;
        .margin-top, .margin-bottom {
          flex: none;
        }
        .margin-center {
          flex: auto;
          display: flex;
          .margin-right, .margin-left {
            position: relative;
            flex: none;
            .watermark {
              cursor: pointer;
            }
            .empty {
              position: absolute;
              left: 0;
              top: 0;
              cursor: pointer;
              width: 100%;
              height: 100%;
              background: url('./assets/empty.png') no-repeat 0 0;
              background-size: 100% 100%;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .page-core {
            flex: auto;
            border: 1px dashed #ccc;
            position: relative;
            .margin-page-num {
              position: absolute;
              display: flex;
              flex-direction: column;
              z-index: 2;
            }
          }
        }
      }
    }
    .operate {
      flex: auto;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      .style-lib {
        flex: none;
        position: relative;
        max-height: 400px;
        border: 1px solid #ccc;
        margin-top: 4px;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          transition: all 0.2s;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          .item {
            width: 220px;
            height: 90px;
            margin: 4px;
            border: 1px solid transparent;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }
            &:hover {
              border-color: $main-color;
            }
            &:hover {
              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .config {
        flex: auto;
        overflow-y: auto;
        padding: 20px;
        .upload-btn {
          padding: 0;
          font-size: 12px;
          height: 17px;
          line-height: 15px;
        }
        .label {
          font-size: 12px;
          color: #606266;
        }
        .connection-box {
          width: 110px;
          position: relative;
          .connection-style {
            position: absolute;
            right: -20px;
            width: 20px;
            top: 14px;
            bottom: 14px;
            border: 1px solid #ccc;
            border-left-color: transparent;
            .icon-connection {
              position: absolute;
              right: -30px;
              top: 60%;
              transform: translate(0, -50%);
            }
          }
        }
        .timeline-title {
          font-weight: 500;
          cursor: pointer;
        }
        /deep/ .el-radio {
          margin-right: 10px;
          font-size: 12px;
        }
        /deep/ .el-radio__label {
          width: unset;
          font-size: 12px;
        }
        /deep/ .el-form-item__label {
          font-size: 12px;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
        }
        .config-item {
          padding-top: 20px;
          padding-left: 10px;
          .text-item, .img-item, .pagenum-item {
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 4px;
              font-size: 13px;
              .style-tool-button--text {
                color: #A2A2A2;
                font-size: 12px;
                &:hover {
                  color: $danger-color;
                }
              }
            }
            .text-body, .img-body, .pagenum-body {
              background-color: #F2F6FC;
              padding: 20px;
            }
          }
          .text-item + .text-item {
            margin-top: 10px;
          }
        }
      }
    }

  }
}
</style>
